import { MessageFormatElement, useIntl } from 'react-intl';

// @deprecated 此方法废弃，会造成大量的报错，使用 useTrans 替代。
export const $i18n = (value1: string, value2?: string): string | MessageFormatElement[] => {
  const locale = useIntl();
  if (value2 === undefined) {
    value2 = value1;
  }
  if (locale.messages[value1] !== undefined) {
    return locale.messages[value1];
  }
  return value2;
};

type TUseTransFunc = (value1: string, value2?: string) => string | MessageFormatElement[];
export const useTrans = (): TUseTransFunc => {
  const locale = useIntl();
  return (value1: string, value2?: string) => {
    if (value2 === undefined) {
      value2 = value1;
    }
    if (locale.messages[value1] !== undefined) {
      return locale.messages[value1];
    }
    return value2;
  };
};

// 处理带有变量的翻译
type TUseTransVarFunc = (id: string, values?: Record<string, any>) => string | MessageFormatElement[];
export const useTransVar = (): TUseTransVarFunc => {
  const intl = useIntl();
  return (id: string, values?: Record<string, any>) => {
    if (values === undefined) {
      return intl.formatMessage({ id });
    }
    return intl.formatMessage({ id }, values);
  };
};
