import axios from '@/utils/axios';
import { serviceHost } from '@/environment';
import qs from 'qs';
import type { Moment } from 'moment';
import { isLocal, isDev } from '@/config/index';

import { ActualSubmittableDemandRequest, ActualSubmittableDemandResponse } from '@/types/httpTypes/actual/submittableDemand';
import { ActualBatchSubmitRequest, ActualBatchSubmitResponse } from '@/types/httpTypes/actual/batchSubmit';

export type { ActualSubmittableDemandRequest, ActualSubmittableDemandResponse } from '@/types/httpTypes/actual/submittableDemand';

export const isMock = false;

const handleDebug = () => {
  if (isLocal) {
    return '?debug=1';
  } else {
    return '';
  }
};

/**
 * typescript 中的 interface 和 type 到底有什么区别？ #7
 * https://github.com/SunshowerC/blog/issues/7
 */
// create modalForm
export type FormLayout = {
  labelCol: { span: number };
  wrapperCol: { span: number };
};
// 共有的
export type TableListItem = {
  id: number;
  name: string;
  approval_code: string;
  create_time: string;
  description: string;
  is_delete: number;
  total_count: number;
  key?: number | string; // 补一个 key
};

export type HistoryListItem = {
  id: number;
  approval_code: string;
  instance_code?: string;
  event_type?: string;
  event_status?: string;
  event_node?: string;
  rule_id?: number;
  success?: number;
  create_time: string;
  context?: Object;
  callback_data?: Object;
  total_count: number;
  key?: number | string; // 补一个 key
};

export type FormItem = {
  offset?: number;
  limit?: number;

  // 组件自带的名字
  current?: number;
  pageSize?: number;
};

export type FormTimeItem = {
  user_name_format?: string;
  user_name_en?: string;
  page?: number;
  per_page?: number;
  period?: string;
  user_name?: string;
  project_name?: string;
  custom_project_name?: string;
  month_list?: string;
  status?: string;

  // 组件自带的名字
  current?: number;
  pageSize?: number;
};

export type IParamsProps = {
  id: string;
  name: string;
}

export type WidgetItem = {
  task_type?: string;
  call_http_api?: string;
};

export type HistoryItem = {
  offset?: number;
  limit?: number;
  approval_code?: string;
  show_all?: number;

  // 组件自带的名字
  current?: number;
  pageSize?: number;
};

export type SonFormItem = {
  offset?: number;
  limit?: number;
  masteRowrId?: number;

  // 组件自带的名字
  current?: number;
  pageSize?: number;
};

export const typeJson = `varchar(m) 变长字符串，大于4096B请使用text
  smallint 整数，2B，范围(-32768~32767)
  int 整数，4B，范围(-2147483648~2147483647)
  bigint 整数，8B，范围(+-9.22*10的18次方)
  decimal(m,n) 小数，参数m<65是总有效位数，d<30且d<m 是小数位
  text 变长字符串，最长65535B
  mediumtext int最大值16MB
  longtext long最大值4GB`;
export const conditionType = [
  { label: 'approval_instance', value: 'approval_instance' },
  { label: 'approval_task', value: 'approval_task' },
];
export const conditionStatusIte = [
  { label: 'PENDING', value: 'PENDING' },
  { label: 'REJECTED', value: 'REJECTED' },
  { label: 'APPROVED', value: 'APPROVED' },
  { label: 'CANCELED', value: 'CANCELED' },
  { label: 'DELETED', value: 'DELETED' },
  { label: 'REVERTED', value: 'REVERTED' },
];
export const conditionStatusRule = [
  { label: 'PENDING', value: 'PENDING' },
  { label: 'REJECTED', value: 'REJECTED' },
  { label: 'APPROVED', value: 'APPROVED' },
  { label: 'DONE', value: 'DONE' },
  { label: 'TRANSFERRED', value: 'TRANSFERRED' },
  { label: 'ROLLBACK', value: 'ROLLBACK' },
];

export const ruleTypeList = [
  { label: 'save_to_db', value: 'save_to_db' },
  { label: 'invoke_feishu_approval', value: 'invoke_feishu_approval' },
  { label: 'call_http_api', value: 'call_http_api' },
];

const getOpposite = (obj: Record<number | string, number | string>) =>
  Object.entries(obj)?.reduce((newObject, [key, value]) => {
    newObject[value] = key;
    return newObject;
  }, {});

export const userInfoRoleEnum: Record<string, number> = {
  admin: 1, // 超级管理员
  read_only: 2, // 所有只读权限
  'inner pm': 3, // 产品/ 平台PM
  hrbp: 4, // HRBP
  tlm: 5, // TLM
  'tlm mentor': 6, // TLM mentor
  'project member': 7, // project member
  'custom pm': 8, // 客户PM
};

export const typeSelectEnum: Record<number, string> = {
  1: '平台项目',
  2: '产品项目',
  3: '其他',
  4: 'Momenta Europe',
};

export const typeMemberOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'cn',
    label: 'CN',
  },
  {
    value: 'eu',
    label: 'EU',
  },
];

export const typeSelectOptions: { label: string, value: string }[] = Object.keys(typeSelectEnum)?.map(item => {
  return { label: typeSelectEnum[item], value: item };
});

export const typeSelectBackEnum: Record<string, number> = getOpposite(typeSelectEnum);

export const pathTabEnum: Record<number, string> = {
  1: 'demand',
  3: 'plan',
  5: 'actual',
  7: 'review',
};

export const pathQueryEnum: Record<string, string> = getOpposite(pathTabEnum);

export const judgeReadOtherType = (type: number): boolean => {
  return type === Number(typeSelectBackEnum['其他']) || type === Number(typeSelectBackEnum['Momenta Europe']);
};

export const percentRender = (text, record) => {
  if (judgeReadOtherType(Number(record?.project_type))) {
    return '-';
  } else {
    return `${text}%`;
  }
};

export type DeatilItem = {
  vm_name: string;
};

export type SaveData = {
  name: string;
  approval_code: string;
  description: string;
};

export type SaveEditData = {
  name: string;
  description: string;
};

export type SavePortData = {
  name: string;
  method: string;
  description: string;
  url: string;
  body: any;
};

export type SaveDemandData = {
  user_id: string;
  custom_project_id: number;
  period: string;
};

export type CopyDemandData = {
  custom_project_id: number;
  demand_id: string;
};

export type CopyPlanData = {
  custom_project_id: number;
  demand_id: string;
  product_project_id: string;
  platform_project_id: string;
  user_id: string;
};

export type SaveDemandItem = {
  project_id: number;
  demand_id: string;
  require_cnt: number;
  l1l2: number;
  l3l4: number;
  l5: number;
  id: number;
};

export type SavePlanItem = {
  custom_project_id: string;
  demand_id: string;
  user_id: string;
  total_percent: string;
  product_percent: string;
  platform_percent: string;
  customization_percent: string;
  product_project_id: string;
  platform_project_id: number;
  comment: string;
  period: string;
  author: string;
};

export type buttonItem = {
  period: string;
};

export type DemandId = {
  demand_id: string;
};

export type CustomData = {
  id: number;
  pm: string;
  project_id: string;
  project_name: string;
};

export type CustomProjectId = {
  project_id: string;
};

export type SaveSnapshot = {
  snapshot_uuid: string;
  vm_name: string;
  snapshot_name: string;
  snapshot_desc: string;
};

export type DeleteSnapshot = {
  snapshot_uuid: string;
  vm_name: string;
};

export type ParamsData = {
  id: number;
};

export type DeleteData = {
  ids: [];
};

export type ParamsCode = {
  target_approval_code: string;
};

interface PageData<T> {
  map(arg0: (item: any) => void);
  data: any;
  total_count: number;
  code?: number;
}

interface ErrorData {
  errmsg?: string;
  errno?: number;
}

interface TableResult<T> extends ErrorData {
  msg(msg: any);
  code: number;
  data: PageData<T>;
}

interface SaveRes {
  errmsg(errmsg: any);
  code?: number;
  data?: any;
  msg?: string;
}

export const deleteData = (id: ParamsData, dataId: ParamsData): Promise<SaveRes> => {
  return axios
    .delete(`${serviceHost}/table/${id}/data/${dataId}`)
    .then((res) => res.data);
};

export const getProjectUserList = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/project_user${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getProjectCustomList = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/project/custom${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const updateProjectCustomList = (data: CustomData): Promise<SaveRes> => {
  return axios
    .put(`${serviceHost}/project_user${handleDebug()}`, data)
    .then((res) => res.data);
};

export const createCustom = (data: CustomData): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/project/custom/create${handleDebug()}`, data)
    .then((res) => res.data);
};

export const deleteCustom = (id: ParamsData): Promise<SaveRes> => {
  return axios
    .delete(`${serviceHost}/project/custom/delete/${id}${handleDebug()}`)
    .then((res) => res.data);
};

export const updateCustom = (id: ParamsData, data: CustomData): Promise<SaveRes> => {
  return axios
    .put(`${serviceHost}/project/custom/update/${id}${handleDebug()}`, data)
    .then((res) => res.data);
};

export const getProjectInnerList = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/project/inner${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const createInner = (data: CustomData): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/project/inner/create${handleDebug()}`, data)
    .then((res) => res.data);
};

export const deleteInner = (id: ParamsData): Promise<SaveRes> => {
  return axios
    .delete(`${serviceHost}/project/inner/delete/${id}${handleDebug()}`)
    .then((res) => res.data);
};

export const updateInner = (id: ParamsData, data: CustomData): Promise<SaveRes> => {
  return axios
    .put(`${serviceHost}/project/inner/update/${id}${handleDebug()}`, data)
    .then((res) => res.data);
};

export const getProjectInnerCalc = (params: buttonItem): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/project/inner/calc${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getDemandList = (id: ParamsData, params): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/demand/${id}${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const saveDemand = (data: SaveDemandData): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/demand${handleDebug()}`, data)
    .then((res) => res.data);
};

export const copyDemand = (data: CopyDemandData): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/demand_item/copy${handleDebug()}`, data)
    .then((res) => res.data);
};

export const saveDemandItem = (data: SaveDemandItem): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/demand_item${handleDebug()}`, data)
    .then((res) => res.data);
};

export const deleteDemandItem = (data: DeleteData): Promise<SaveRes> => {
  return axios
    .delete(`${serviceHost}/demand_item${handleDebug()}`, { data })
    .then((res) => res.data);
};

export const updateDemandItem = (data: SaveDemandItem): Promise<SaveRes> => {
  return axios
    .put(`${serviceHost}/demand_item${handleDebug()}`, data)
    .then((res) => res.data);
};

export const submitDemand = (data: DemandId): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/demand/submit${handleDebug()}`, data)
    .then((res) => res.data);
};

export const getDemandAll = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/demand_all${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getPlanList = (
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/plan${handleDebug()}`)
    // .get('https://yapi.mmtwork.com/mock/281/api/plan')
    .then((res) => res.data);
};

export const savePlanItem = (data: SavePlanItem): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/plan${handleDebug()}`, data)
    .then((res) => res.data);
};

export const copyPlan = (data: CopyPlanData): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/plan/copy${handleDebug()}`, data)
    .then((res) => res.data);
};

export const submitPlan = (data: ParamsData): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/plan/submit${handleDebug()}`, data)
    .then((res) => res.data);
};

export const deletePlanItem = (data: DeleteData): Promise<SaveRes> => {
  return axios
    .delete(`${serviceHost}/plan${handleDebug()}`, { data })
    .then((res) => res.data);
};

export const updatePlanItem = (data: SavePlanItem): Promise<SaveRes> => {
  return axios
    .put(`${serviceHost}/plan${handleDebug()}`, data)
    .then((res) => res.data);
};

export const getPlanAll = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/plan_all${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getPlanDemand = (params: FormTimeItem): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/plan/demand_list${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getaAtualList = (
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/actual${handleDebug()}`)
    // .get('https://yapi.mmtwork.com/mock/281/api/actual')
    .then((res) => res.data);
};

export const saveActualItem = (data: SavePlanItem): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/actual${handleDebug()}`, data)
    .then((res) => res.data);
};

export const copyActual = (data: CopyPlanData): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/actual/copy${handleDebug()}`, data)
    .then((res) => res.data);
};

export const submitActual = (data: ParamsData): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/actual/submit${handleDebug()}`, data)
    .then((res) => res.data);
};

export const deleteActualItem = (data: DeleteData): Promise<SaveRes> => {
  return axios
    .delete(`${serviceHost}/actual${handleDebug()}`, { data })
    .then((res) => res.data);
};

export const updateActualItem = (data: SavePlanItem): Promise<SaveRes> => {
  return axios
    .put(`${serviceHost}/actual${handleDebug()}`, data)
    .then((res) => res.data);
};

export const getActualAll = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/actual_all${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getActualDemand = (params: FormTimeItem): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/actual/demand_list${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getMonthlyCnt = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/demand/monthly_cnt${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getMonthlyDemand = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/demand/list${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getMonthlyPlan = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/plan/monthly_cnt${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getMonthlyCustom = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/project/custom/monthly_cnt${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getMonthlyInner = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/project/inner/monthly_cnt${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getMonthlyUser = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/project_user/monthly_cnt${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getMonthlyActual = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/actual/monthly_cnt${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const getPrrojectInnerTree = (
  params: FormTimeItem,
): Promise<SaveRes> => {
  return axios
    .get(`${serviceHost}/project/inner/tree${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const demandMessage = (data: buttonItem): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/demand/message${handleDebug()}`, data)
    .then((res) => res.data);
};

export const planMessage = (data: buttonItem): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/plan/message${handleDebug()}`, data)
    .then((res) => res.data);
};

export const actualMessage = (data: buttonItem): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/actual/message${handleDebug()}`, data)
    .then((res) => res.data);
};

export const DemandGroupChat = (data: buttonItem): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/demand/create_group_chat${handleDebug()}`, data)
    .then((res) => res.data);
};

export const PlanGroupChat = (data: buttonItem): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/plan/create_group_chat${handleDebug()}`, data)
    .then((res) => res.data);
};

export const ActualGroupChat = (data: buttonItem): Promise<SaveRes> => {
  return axios
    .post(`${serviceHost}/actual/create_group_chat${handleDebug()}`, data)
    .then((res) => res.data);
};

export const demandSaveDraft = (demandId, data: buttonItem): Promise<SaveRes> => {
  return axios
    .put(`${serviceHost}/demand/change_status/${demandId}${handleDebug()}`, data)
    .then((res) => res.data);
};

export const planSaveDraft = (demandId): Promise<SaveRes> => {
  return axios
    .put(`${serviceHost}/plan/change_status/${demandId}${handleDebug()}`)
    .then((res) => res.data);
};

export const actualSaveDraft = (demandId): Promise<SaveRes> => {
  return axios
    .put(`${serviceHost}/actual/change_status/${demandId}${handleDebug()}`)
    .then((res) => res.data);
};

export const fetchSubmittableDemand = (
  params: ActualSubmittableDemandRequest,
): Promise<ActualSubmittableDemandResponse> => {
  return axios
    .get(`${serviceHost}/actual/submittable_demand${handleDebug()}`, { params })
    .then((res) => res.data);
};

export const demandBatchSubmit = (
  params: ActualBatchSubmitRequest,
): Promise<ActualBatchSubmitResponse> => {
  return axios
    .post(`${serviceHost}/actual/batch_submit${handleDebug()}`, params)
    .then((res) => res.data);
};
