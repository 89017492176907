import { lazy } from 'react';
import {
  DesktopOutlined,
  HighlightOutlined,
  CloudOutlined,
  DashboardOutlined,
  TableOutlined,
  FormOutlined,
  ProfileOutlined,
  HomeOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import { isLocal, isDev } from './index';

interface IMetaItem {
  key?: string;
}

export type RouteItem = {
  name: string;
  intlName?: string; // 使用翻译的 name
  key: string;
  meta?: string[] | IMetaItem[]; // Breadcrumb 节点
  show?: boolean; // 是否在侧边栏展示
  icon?: any; // 一级导航需要，二级不需要
  path?: string;
  component?: React.LazyExoticComponent<React.FC<any>>;
  children?: RouteItem[];
};

export const routes: RouteItem[] = [
  {
    name: 'login',
    key: 'login',
    show: false,
    path: '/',
    component: lazy(() => import('../pages/login')),
  },
  {
    name: '工时填报',
    key: 'demandReport',
    path: '/fill-report/demand-report',
    show: true,
    component: lazy(() => import('../pages/report/work-time/index')),
  },
  {
    name: '报表',
    key: 'dashboard',
    show: true,
    path: '/dashboard',
    component: lazy(() => import('../pages/dashboard/index')),
  },
  {
    name: '设置',
    key: 'setting',
    path: '/setting',
    show: false,
    component: lazy(() => import('../pages/setting')),
  },
  {
    name: '基础数据',
    key: 'basicsData',
    path: '/basics-data',
    show: true,
    children: [
      {
        name: '项目人员',
        key: 'projectUser',
        path: '/basics-data/project-user',
        show: true,
        meta: ['基础数据'],
        component: lazy(() => import('../pages/basics/index')),
      },
      {
        name: '项目人员详情',
        key: 'projectUserDetail',
        path: '/basics-data/project-user/:name',
        show: false,
        meta: ['基础数据'],
        component: lazy(() => import('../pages/basics/user/detail')),
      },
      {
        name: '客户项目',
        key: 'customProject',
        path: '/basics-data/custom-project',
        meta: ['基础数据'],
        show: true,
        component: lazy(() => import('../pages/basics/index')),
      },
      {
        name: '客户项目详情',
        key: 'customerProjectDetail',
        path: '/basics-data/custom-project/:name',
        meta: ['基础数据'],
        show: false,
        component: lazy(() => import('../pages/basics/custom/detail')),
      },
      {
        name: '产品平台项目',
        key: 'innerProject',
        path: '/basics-data/inner-project',
        meta: ['基础数据'],
        show: true,
        component: lazy(() => import('../pages/basics/index')),
      },
      {
        name: '产品平台项目详情',
        key: 'innerProjectDetail',
        path: '/basics-data/inner-project/:name',
        meta: ['基础数据'],
        show: false,
        component: lazy(() => import('../pages/basics/inner/detail')),
      },
    ],
  },
  // {
  //   name: '需求填报',
  //   key: 'demandReport',
  //   path: '/fill-report/demand-report',
  //   show: true,
  //   meta: ['填报管理'],
  //   component: lazy(() => import('../pages/report/index')),
  // },
  // {
  //   name: '需求填报详情',
  //   key: 'demandReportDetail',
  //   path: '/fill-report/demand-report/:name',
  //   show: false,
  //   meta: ['填报管理'],
  //   component: lazy(() => import('../pages/report/tab-detail')),
  // },
  // {
  //   name: '规划填报',
  //   key: 'planReport',
  //   path: '/fill-report/plan-report',
  //   meta: ['填报管理'],
  //   show: true,
  //   component: lazy(() => import('../pages/report/index')),
  // },
  // {
  //   name: '规划填报详情',
  //   key: 'planReportDetail',
  //   path: '/fill-report/plan-report/:name',
  //   meta: ['填报管理'],
  //   show: false,
  //   component: lazy(() => import('../pages/report/tab-detail')),
  // },
  // {
  //   name: '使用填报',
  //   key: 'actualReport',
  //   path: '/fill-report/actual-report',
  //   meta: ['填报管理'],
  //   show: true,
  //   component: lazy(() => import('../pages/report/index')),
  // },
  // {
  //   name: '使用填报详情',
  //   key: 'actualReportDetail',
  //   path: '/fill-report/actual-report/:name',
  //   meta: ['填报管理'],
  //   show: false,
  //   component: lazy(() => import('../pages/report/tab-detail')),
  // },
];
