export default {
  'header.description': '工时填报',
  'header.PROJECT_NAME': '工时填报',
  home: 'home',
  业务方向具体要求: 'Specific Requirements',
  待规划: 'Unplanned',
  已规划: 'Planned',
  待填报: 'Unfilled',
  已填报: 'Filled',
  报表: 'Dashboard',
  工时填报: 'Working Hours',
  '上传成功！': 'Upload succeeded!',
  上传失败: 'Upload failed',
  项目名称: 'Proj. Name',
  项目编码: 'Proj. Code',
  需求月: 'Demand Month',
  项目PM: 'PM',
  项目PM员工编号: 'PM Employee ID',
  项目PM邮箱: 'PM Email',
  HRBP: 'HRBP',
  项目优先级: 'Proj. Priority',
  项目备注: 'Proj. Notes',
  查看: 'View',
  '删除此行？': 'Delete this row?',
  确定: 'Confirm',
  取消: 'Cancel',
  删除: 'Delete',
  客户项目: 'Customer Proj.',
  新建客户项目: 'New Customer Proj.',
  批量导入: 'Batch Import',
  全量导出: 'Export All',
  导入客户项目数据: 'Import Customer Proj. Data',
  '1. 下载导入模板': '1. Download import template',
  根据提示信息完善表格内容: 'Complete the table content according to the prompt information',
  下载空的模板表格: 'Download an empty template table',
  '2. 上传完善后的模版': '2. Upload the improved template',
  '下载模板并完善信息后，可直接将文件拖拽到此处进行上传。': 'After downloading the template and improving the information, you can drag the file directly here for uploading.',
  '创建成功！': 'Created successfully!',
  '更新成功！': 'Updated successfully!',
  接口出错: 'Interface error',
  新建产品平台项目: 'New Product Platform Proj.',
  产品平台项目详情: 'Product Platform Proj. Details',
  编辑产品平台项目: 'Edit Product Platform Proj.',
  编辑: 'Edit',
  创建: 'Create',
  更新: 'Update',
  项目类型: 'Proj. Type',
  主项目: 'Main Proj.',
  一级子项目: 'Level-1 Subproj.',
  二级子项目: 'Level-2 Subproj.',
  一级部门: 'Level-1 Dept',
  二级部门: 'Level-2 Dept',
  三级部门: 'Level-3 Dept',
  最小部门人数: 'Minimum Dept Size',
  'L1-L2': 'L1-L2',
  'L3-L4': 'L3-L4',
  L5及以上: 'L5 and above',
  本月需求人数占用: 'Demand HC This Month',
  当前可用人数: 'Current Available HC',
  PM: 'PM',
  TLM: 'TLM',
  协作人: 'Collaborator',
  是否能对应ODC: 'Can Match ODC',
  是: 'Yes',
  否: 'No',
  '人数重置成功！': 'HC reset successfully!',
  ID: 'ID',
  备注: 'Note',
  产品平台项目: 'Product Platform Proj.',
  人数重置: 'Reset No.',
  导入产品平台项目数据: 'Import Product Platform Proj. Data',
  下载导入模板: 'Download Import Template',
  新建项目人员: 'Create Proj. Member',
  项目人员详情: 'Proj. Member Details',
  编辑项目人员: 'Edit Proj. Member',
  员工姓名: 'Employee Name',
  平台项目: 'Platform Proj.',
  产品项目: 'Product Proj.',
  员工编号: 'Employee ID',
  员工邮箱: 'Employee Email',
  员工职级: 'Rank',
  员工属性: 'Employee Attribute',
  员工实际剩余可用占比: 'Employee Actual Available Ratio',
  员工规划剩余可用占比: 'Employee Planned Available Ratio',
  Mentor邮箱: 'Mentor Email',
  Mentor员工编号: 'Mentor Employee ID',
  一级部门负责人姓名: 'Level 1 Dept Manager Name',
  一级部门负责人邮箱: 'Level 1 Dept Manager Email',
  一级部门负责人员工编号: 'Level 1 Dept Manager Employee ID',
  二级部门负责人姓名: 'Level 2 Dept Manager Name',
  二级部门负责人邮箱: 'Level 2 Dept Manager Email',
  二级部门负责人员工编号: 'Level 2 Dept Manager Employee ID',
  三级部门负责人姓名: 'Level 3 Dept Manager Name',
  三级部门负责人邮箱: 'Level 3 Dept Manager Email',
  三级部门负责人员工编号: 'Level 3 Dept Manager Employee ID',
  四级部门: 'Level 4 Dept',
  四级部门负责人姓名: 'Level 4 Dept Manager Name',
  四级部门负责人邮箱: 'Level 4 Dept Manager Email',
  四级部门负责人员工编号: 'Level 4 Dept Manager Employee ID',
  项目人员HRBP: 'Proj. Member HRBP',
  项目人员TLM: 'Proj. Member TLM',
  项目人员TLM的Mentor: 'Proj. Member TLM Mentor',
  员工状态: 'Employee Status',
  离职: 'Resigned',
  在职: 'Employed',
  '删除主表数据会级联删除所有子表对应数据，确定删除吗?': 'Deleting the main table data will also delete all corresponding data in the sub-tables. Are you sure you want to delete?',
  产品平台项目PM: 'PPM',
  导入数据: 'Import Data',
  选择上传类型: 'Select Upload Type',
  增量上传: 'Incremental Upload',
  替换上传: 'Replace Upload',
  本月: 'Current month',
  项目人员数量: 'Number of Proj. personnel',
  项目数量: 'Number of Proj.',
  时间: 'Time',
  项目月份: 'Month',
  填报预览: 'Preview the report',
  待提交客户PM: 'Unsubmitted CPM',
  待规划产品平台PM: 'Unplanned PPM',
  待填报产品平台PM: 'Unfilled PPM',
  客户项目PM: 'CPM',
  需求单: 'Demand',
  需求项: 'Demand item',
  导出: 'Export',
  总人数: 'Total Number',
  HC需求: 'HC Demand',
  已提需求单: 'Demand Submitted',
  待提需求单: 'Demand Unsubmitted',
  需求明细: 'Demand Details',
  需求人月: 'Demand Person-month',
  HC规划: 'HC Planning',
  需规划: 'Demand Planning',
  涉及PM: 'PPM',
  已完成: 'Completed',
  未完成: 'Incomplete',
  HC使用: 'HC Filling',
  需填报: 'Required Filled',
  产品项目数量: 'Product Proj.',
  平台项目数量: 'Platform Proj.',
  其他项目数量: 'Other Proj.',
  项目人员: 'Proj. personnel',
  已规划人数: 'Planned',
  待规划人数: 'Unplanned',
  已填报人数: 'Filled',
  待填报人数: 'Unfilled',
  员工信息: 'Employee Information',
  姓名: 'Name',
  职级: 'Rank',
  总占比: 'Total Proportion',
  '请填写10%的倍数': 'Please fill in multiples of 10%',
  其中定制占比: 'Custom Proportion',
  '请填写5%的倍数': 'Please fill in multiples of 5%',
  其中产品项目占比: 'Product Proj. Proportion',
  其中平台项目占比: 'Platform Proj. Proportion',
  填报人员: 'Report fillers',
  需求月份: 'Demand month',
  需求编码: 'Demand code',
  '复制成功！': 'Copy successful!',
  '删除成功！': 'Deletion successful!',
  '确定删除所选行信息吗？': 'Are you sure you want to delete the selected row?',
  '提交成功！': 'Submitted successfully!',
  '离开？': ' leave?',
  '您当前页面有编辑尚未单行保存，未保存内容将丢失，是否确定': 'You have unsaved changes on the current page. Are you sure you want to  without saving',
  '修改成功！': 'modified successfully!',
  '您当前页面有编辑尚未单行保存，请先处理！': 'You have unsaved changes on the current page. Please handle them first!',
  需求填报: 'Demand Fill',
  规划填报: 'Planning Fill',
  使用填报: 'Actual Fill',
  存为待填报: 'Save as Unfilled',
  提交: 'Submit',
  需求项目名称: 'Demand Proj. Name',
  需求人数合计: 'Total Demand',
  填报人数合计: 'Total Filling',
  复制本月HC规划单规划明细数据: 'Copy HC Plan Details for This Month',
  一键复制: 'Copy All',
  选择删除: 'Select and Delete',
  新增填报明细: 'Add Filling Details',
  保存: 'Save',
  需求ID: 'Demand ID',
  状态: 'Status',
  客户项目名称: 'Customer Proj. Name',
  '产品/平台项目': 'Product/Platform Proj.',
  需求总人数: 'Total',
  部门: 'Dept',
  '催办成功！': 'Reminder Sent Successfully!',
  '建群成功！': 'Group Created Successfully!',
  '向本人所负责的且当前状态处于待填报的产品/平台项目PM推送提醒消息': 'Send Reminder to PPM Who I am Responsible for and in "Unfilled" Status',
  催办: 'Remind',
  '以点击人员为群主，本人所负责的待填报的产品/平台项目PM为群成员创建飞书群，并发送使用填报提醒消息': 'Create Feishu Group and Send Reminder to PPM Who I am Responsible for and in "Unfilled" Status with Me as Group Owner',
  一键拉群: 'Create Group',
  需求信息: 'Demand Information',
  '产品项目&平台项目': 'Product Proj. & Platform Proj.',
  '产品/平台项目名称': 'Product/Platform Proj.',
  '产品/平台项目PM': 'PPM',
  部门TLM: 'TLM',
  部门TLM的Mentor: 'TLM Mentor',
  部门HRBP: 'HRBP',
  部门人数: 'Dept HC',
  '人数&级别': 'Number & Level',
  存为待提交: 'Save as "Unsubmitted"',
  需求单号: 'Demand NO.',
  待提交: 'Unsubmitted',
  已提交: 'Submitted',
  客户项目编码: 'Customer Proj. Code',
  向本人所负责的且当前状态处于待提交的客户项目PM推送提醒消息: 'Send Reminder to CPM Who I am Responsible for and in "Unsubmitted" Status',
  '以点击人员为群主，本人所负责的待提交的客户项目PM为群成员创建飞书群，并发送需求填报提醒消息': 'Create Feishu Group and Send Reminder to PPM Who I am Responsible for and in "Unsubmitted" Status with Me as Group Owner',
  存为待规划: 'Save as "Unplanned"',
  规划人数合计: 'Total Planned',
  复制上月HC使用填报单填报明细数据: 'Copy HC details from last month filling',
  新增规划明细: 'Add new planning details',
  '向本人所负责的且当前状态处于待规划的产品/平台项目PM推送提醒消息': 'Push reminder message to PPM in the "planning" phase',
  '以点击人员为群主，本人所负责的待规划的产品/平台项目PM为群成员创建飞书群，并发送规划填报提醒消息': 'Create a Feishu group with the person who clicked as the owner and PPM in the "planning" phase as members, and send planning filling reminder messages',
  一月: 'Jan',
  二月: 'Feb',
  三月: 'Mar',
  四月: 'Apr',
  五月: 'May',
  六月: 'Jun',
  七月: 'Jul',
  八月: 'Aug',
  九月: 'Sep',
  十月: 'Oct',
  十一月: 'Nov',
  十二月: 'Dec',
  项目月: 'Month',
  应提需求单: 'Expected Demand',
  未提需求单: 'Unsubmitted Demand',
  需规划人月: 'Required Planned Man-month',
  已规划人月: 'Planned Man-month',
  需填报人月: 'Required Filled Man-month',
  已填报人月: 'Filled Man-month',
  统计看板: 'Statistics Board',
  需求阶段: 'Demand',
  规划阶段: 'Planning',
  规划审核: 'Planning Review',
  填报阶段: 'Filling',
  审核阶段: 'Review',
  核定阶段: 'Verification',
  归档: 'Archiving',
  其他: 'Other',
  全部: 'All',
  客户项目详情: 'Customer Proj. Details',
  编辑客户项目: 'Edit Customer Proj.',
  项目员工编号: 'Proj. Employee ID',
  项目邮箱: 'Proj. Email',
  复制上月需求单中需求明细数据: 'Copy demand details from last month demand',
  新增需求明细: 'Add new demand details',
  '确定删除 {username}': 'Are you sure to delete {username} ?',
  查看审批: 'Review Approval',
  发起审批: 'Submit Approval',
  审批摘要: 'Approval Summary',
  标题: 'Approval Title',
  单号: 'Request No.',
  发起人: 'Submitted by',
  发起时间: 'Submitted',
  抄送: 'cc',
  拒绝: 'Rejected',
  批量确认: 'Batch Confirmation',
  使用填报确认: 'Confirmation',
  待确认人数: 'Awaiting Confirmation',
  合计: 'Total',
  TLM的Mentor: 'TLM Mentor',
  审批流程: 'Approval Record',
  待审批: 'Awaiting',
  审批中: 'Reviewing',
  审批通过: 'Approved',
  异常: 'Error',

  'login.logout': 'Logout',

  // 通用
  Delete: 'Delete',
  Edit: 'Edit',
  'No Permission': 'No Permission',
  'Server Error': 'Server Error',
};
