import React, { FC, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import BasicLayout from './components/layout';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import langMap, { LangConf } from './locales';
import { getLang, storeLang } from './utils/lang';
import './main.less';
import '@/utils/sentry';
import { isDev, isPro } from '@/config/index';
import VConsole from 'vconsole';
import { LoginProvider } from './login';

function setVConsole() {
  if (!isPro) {
    const vc = new VConsole();
    localStorage.setItem('vConsole_switch_y', (window.innerHeight / 2 + ''));
    localStorage.setItem('vConsole_switch_x', '0');
  }
}
setVConsole();

function showSystemInfo() {
  const { __GIT_DESCRIBE__, __BUILD_TIME__ } = window?.preEnv || {};
  console.info('[GIT_DESCRIBE]', __GIT_DESCRIBE__);
  console.info('[BUILD_TIME]', moment(__BUILD_TIME__).format('YYYY-MM-DD HH:mm:ss'));
  console.info(`From Now: ${moment(__BUILD_TIME__).fromNow()}. Typeof: ${typeof __BUILD_TIME__}`);
  console.info('NOW:', moment().format('YYYY-MM-DD HH:mm:ss'), 'NODE_ENV:', process.env.NODE_ENV);
  console.info('REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT);
}
showSystemInfo();

const getAntdLocale = (locale: string) => {
  const antdLocaleMap = {
    en: enUS,
    zh: zhCN,
  };
  return antdLocaleMap[locale as keyof typeof antdLocaleMap] || enUS;
};

const App: FC = () => {
  const [lang, setLang] = useState(getLang());

  const changeLang = (value: string): void => {
    setLang(value);
    storeLang(value);
  };

  const messages: LangConf = langMap[lang] ? langMap[lang] : langMap['en-US'];
  moment.locale(messages.locale);
  const antdLocale = getAntdLocale(messages.locale);

  useEffect(() => {
    const titleName = isDev ? 'WHS' : '工时填报';
    const titleFormat: string = lang === 'en-US' ? 'Working Hours' : titleName;
    document.title = titleFormat;
  }, [lang]);

  return (
    <LoginProvider>
      <IntlProvider messages={messages} locale={messages.locale}>
        <ConfigProvider locale={antdLocale}>
          <BasicLayout changeLang={changeLang} />
        </ConfigProvider>
      </IntlProvider>
    </LoginProvider>
  );
};

export default App;
