import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

interface PrivateRouteParams extends RouteProps {
  path?: string | string[];
  changeLang: (string) => void;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export const PrivateRoute: React.FC<PrivateRouteParams> = ({
  component: Component,
  ...rest
}) => {
  const { keycloak } = useKeycloak();

  React.useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak, keycloak.authenticated]);

  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak?.authenticated ? <Component {...props} /> : null
      }
    />
  );
};
