import axios from '@/utils/axios';
import { serviceHost } from '@/environment';
import {
  ILoginResponse,
  IAppIdResponse,
  IConfigParametersRequest,
  IConfigParametersResponse,
  ICallbackRequest,
  ICallbackResponse,
} from '@/feishu/typing';

export const checkIfLogin = (): Promise<ILoginResponse> => {
  return axios
    .get(`${serviceHost}/is_login`)
    .then((res) => res.data);
};

export const fetchAppId = (): Promise<IAppIdResponse> => {
  return axios
    .get(`${serviceHost}/get_appid`)
    .then((res) => res.data);
};

export const fetchConfigParameters = (params: IConfigParametersRequest): Promise<IConfigParametersResponse> => {
  return axios
    .get(`${serviceHost}/get_config_parameters`, { params })
    .then((res) => res.data);
};

export const fetchCallback = (params: ICallbackRequest): Promise<ICallbackResponse> => {
  return axios
    .get(`${serviceHost}/callback`, { params })
    .then((res) => res?.data?.data || res.data);
};
