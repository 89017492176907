type IDebug = Console;

const debugConsole: IDebug = {
  ...console,
};

// 通过 debug 可打印信息
// sessionStorage.setItem('debug', true);
const isDebug = window.sessionStorage.getItem('debug') === 'true';
if (!isDebug) {
  debugConsole.info = () => undefined;
  debugConsole.error = () => undefined;
}

export default debugConsole;
