import {
  checkIfLogin,
  fetchAppId,
  fetchCallback,
  fetchConfigParameters,
} from '@/feishu/services';

import type {
  IAuthClient,
  IAppIdResponse,
  ICallbackRequest,
  ICallbackResponse,
  ILoginResponse,
  IConfigParametersRequest,
  IConfigParametersResponse,
} from '@/feishu/typing';

// 登录链接
export const loginPath = '/login'; // 可能不需要
export const loginUrl = '/'; // 默认 `/`（单斜杠）

export const feishuConfig: IAuthClient = {
  loginPath,
  loginUrl,
  checkIfLogin: async (): Promise<ILoginResponse> => {
    const data = await checkIfLogin();
    return data;
  },
  fetchAppId: async (): Promise<IAppIdResponse> => {
    const data = await fetchAppId();
    return data;
  },
  fetchCallback: async (code: ICallbackRequest): Promise<ICallbackResponse> => {
    const data = await fetchCallback(code);
    return data;
  },
  fetchConfigParameters: async (params: IConfigParametersRequest): Promise<IConfigParametersResponse> => {
    const data = await fetchConfigParameters(params);
    return data;
  },
};
