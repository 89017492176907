import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
Sentry.init({
  dsn: 'https://4cbf60c16ee6449c8a71e6702af7e5be@sentry.mmtwork.com/26',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
