export default {
  'header.description': '工时填报',
  'header.PROJECT_NAME': '工时填报',
  'home':'home',
  '工时填报': '工时填报',
  '报表':'报表',
  '上传成功！': '上传成功！',
  '上传失败': '上传失败',
  '项目名称': '项目名称',
  '项目编码': '项目编码',
  '需求月': '需求月',
  '项目PM': '项目PM',
  '项目PM员工编号': '项目PM员工编号',
  '项目PM邮箱': '项目PM邮箱',
  'HRBP': 'HRBP',
  '项目优先级': '项目优先级',
  '项目备注': '项目备注',
  '查看': '查看',
  '删除此行？': '删除此行？',
  '确定': '确定',
  '取消': '取消',
  '删除': '删除',
  '客户项目': '客户项目',
  '新建客户项目': '新建客户项目',
  '批量导入': '批量导入',
  '全量导出': '全量导出',
  '导入客户项目数据': '导入客户项目数据',
  '1. 下载导入模板': '1. 下载导入模板',
  '根据提示信息完善表格内容': '根据提示信息完善表格内容',
  '下载空的模板表格': '下载空的模板表格',
  '2. 上传完善后的模版': '2. 上传完善后的模版',
  '下载模板并完善信息后，可直接将文件拖拽到此处进行上传。': '下载模板并完善信息后，可直接将文件拖拽到此处进行上传。',
  '创建成功！': '创建成功！',
  '更新成功！': '更新成功！',
  '接口出错': '接口出错',
  '新建产品平台项目': '新建产品平台项目',
  '产品平台项目详情': '产品平台项目详情',
  '编辑产品平台项目': '编辑产品平台项目',
  '编辑': '编辑',
  '创建': '创建',
  '更新': '更新',
  '项目类型': '项目类型',
  '主项目': '主项目',
  '一级子项目': '一级子项目',
  '二级子项目': '二级子项目',
  '一级部门': '一级部门',
  '二级部门': '二级部门',
  '三级部门': '三级部门',
  '最小部门人数': '最小部门人数',
  'L1-L2': 'L1-L2',
  'L3-L4': 'L3-L4',
  'L5及以上': 'L5及以上',
  '本月需求人数占用': '本月需求人数占用',
  '当前可用人数': '当前可用人数',
  'PM': 'PM',
  'TLM': 'TLM',
  'TLM的Mentor': 'TLM的Mentor',
  '协作人': '协作人',
  '是否能对应ODC': '是否能对应ODC',
  '是': '是',
  '否': '否',
  '人数重置成功！': '人数重置成功！',
  'ID': 'ID',
  '备注': '备注',
  '产品平台项目': '产品平台项目',
  '人数重置': '人数重置',
  '导入产品平台项目数据': '导入产品平台项目数据',
  '下载导入模板': '下载导入模板',
  '新建项目人员': '新建项目人员',
  '项目人员详情': '项目人员详情',
  '编辑项目人员': '编辑项目人员',
  '员工姓名': '员工姓名',
  '平台项目': '平台项目',
  '产品项目': '产品项目',
  '员工编号': '员工编号',
  '员工邮箱': '员工邮箱',
  '员工职级': '员工职级',
  '员工属性': '员工属性',
  '员工实际剩余可用占比': '员工实际剩余可用占比',
  '员工规划剩余可用占比': '员工规划剩余可用占比',
  'Mentor邮箱': 'Mentor邮箱',
  'Mentor员工编号': 'Mentor员工编号',
  '一级部门负责人姓名': '一级部门负责人姓名',
  '一级部门负责人邮箱': '一级部门负责人邮箱',
  '一级部门负责人员工编号': '一级部门负责人员工编号',
  '二级部门负责人姓名': '二级部门负责人姓名',
  '二级部门负责人邮箱': '二级部门负责人邮箱',
  '二级部门负责人员工编号': '二级部门负责人员工编号',
  '三级部门负责人姓名': '三级部门负责人姓名',
  '三级部门负责人邮箱': '三级部门负责人邮箱',
  '三级部门负责人员工编号': '三级部门负责人员工编号',
  '四级部门': '四级部门',
  '四级部门负责人姓名': '四级部门负责人姓名',
  '四级部门负责人邮箱': '四级部门负责人邮箱',
  '四级部门负责人员工编号': '四级部门负责人员工编号',
  '项目人员HRBP': '项目人员HRBP',
  '项目人员TLM': '项目人员TLM',
  '项目人员TLM的Mentor': '项目人员TLM的Mentor',
  '员工状态': '员工状态',
  '离职': '离职',
  '在职': '在职',
  '删除主表数据会级联删除所有子表对应数据，确定删除吗?': '删除主表数据会级联删除所有子表对应数据，确定删除吗？',
  '产品平台项目PM': '产品平台项目PM',
  '导入数据': '导入数据',
  '选择上传类型': '选择上传类型',
  '增量上传': '增量上传',
  '替换上传': '替换上传',
  '本月': '本月',
  '项目人员数量': '项目人员数量',
  '项目数量': '项目数量',
  '时间': '时间',
  '项目月份': '项目月份',
  '填报预览': '填报预览',
  '待提交客户PM': '待提交客户PM',
  '待规划产品平台PM': '待规划产品平台PM',
  '待填报产品平台PM': '待填报产品平台PM',
  '客户项目PM': '客户项目PM',
  '需求单': '需求单',
  '需求项': '需求项',
  '导出': '导出',
  '总人数': '总人数',
  'HC需求':'HC需求',
  '已提需求单':'已提需求单',
  '待提需求单':'待提需求单',
  '需求明细':'需求明细',
  '需求人月':'需求人月',
  'HC规划':'HC规划',
  '需规划':'需规划',
  '已规划':'已规划',
  '待规划':'待规划',
  '涉及PM':'涉及PM',
  '已完成':'已完成',
  '未完成':'未完成',
  'HC使用':'HC使用',
  '需填报':'需填报',
  '已填报':'已填报',
  '待填报':'待填报',
  '产品项目数量':'产品项目数量',
  '平台项目数量':'平台项目数量',
  '其他项目数量':'其他项目数量',
  '项目人员':'项目人员',
  '已规划人数':'已规划人数',
  '待规划人数':'待规划人数',
  '已填报人数':'已填报人数',
  '待填报人数':'待填报人数',
  '员工信息':'员工信息',
  '姓名':'姓名',
  '职级':'职级',
  '总占比':'总占比',
  '请填写10%的倍数':'请填写10%的倍数',
  '其中定制占比':'其中定制占比',
  '请填写5%的倍数':'请填写5%的倍数',
  '其中产品项目占比':'其中产品项目占比',
  '其中平台项目占比':'其中平台项目占比',
  '填报人员':'填报人员',
  '需求月份':'需求月份',
  '需求编码':'需求编码',
  '复制成功！':'复制成功！',
  '删除成功！':'删除成功！',
  '确定删除所选行信息吗？':'确定删除所选行信息吗？',
  '提交成功！':'提交成功！',
  '离开？':'离开？',
  '您当前页面有编辑尚未单行保存，未保存内容将丢失，是否确定': '您当前页面有编辑尚未单行保存，未保存内容将丢失，是否确定',
  '修改成功！':'修改成功！',
  '您当前页面有编辑尚未单行保存，请先处理！':'您当前页面有编辑尚未单行保存，请先处理！',
  '需求填报':'需求填报',
  '规划填报':'规划填报',
  '使用填报':'使用填报',
  '存为待填报':'存为待填报',
  '提交':'提交',
  '需求项目名称': '需求项目名称',
  '需求人数合计': '需求人数合计',
  '填报人数合计': '填报人数合计',
  '复制本月HC规划单规划明细数据': '复制本月HC规划单规划明细数据',
  '一键复制': '一键复制',
  '选择删除': '选择删除',
  '新增填报明细': '新增填报明细',
  '保存': '保存',
  '需求ID': '需求ID',
  '状态': '状态',
  '客户项目名称': '客户项目名称',
  '业务方向具体要求': '业务方向具体要求',
  '产品/平台项目': '产品/平台项目',
  '需求总人数': '需求总人数',
  '部门': '部门',
  '催办成功！': '催办成功！',
  '建群成功！': '建群成功！',
  '向本人所负责的且当前状态处于待填报的产品/平台项目PM推送提醒消息': '向本人所负责的且当前状态处于待填报的产品/平台项目PM推送提醒消息',
  '催办': '催办',
  '以点击人员为群主，本人所负责的待填报的产品/平台项目PM为群成员创建飞书群，并发送使用填报提醒消息': '以点击人员为群主，本人所负责的待填报的产品/平台项目PM为群成员创建飞书群，并发送使用填报提醒消息',
  '一键拉群': '一键拉群',
  '需求信息': '需求信息',
  '产品项目&平台项目': '产品项目&平台项目',
  '产品/平台项目名称': '产品/平台项目名称',
  '产品/平台项目PM': '产品/平台项目PM',
  '部门TLM': '部门TLM',
  '部门TLM的Mentor': '部门TLM的Mentor',
  '部门HRBP': '部门HRBP',
  '部门人数': '部门人数',
  '人数&级别': '人数&级别',
  '存为待提交': '存为待提交',
  '需求单号': '需求单号',
  '待提交': '待提交',
  '已提交': '已提交',
  '客户项目编码': '客户项目编码',
  '向本人所负责的且当前状态处于待提交的客户项目PM推送提醒消息': '向本人所负责的且当前状态处于待提交的客户项目PM推送提醒消息',
  '以点击人员为群主，本人所负责的待提交的客户项目PM为群成员创建飞书群，并发送需求填报提醒消息': '以点击人员为群主，本人所负责的待提交的客户项目PM为群成员创建飞书群，并发送需求填报提醒消息',
  '存为待规划': '存为待规划',
  '规划人数合计': '规划人数合计',
  '复制上月HC使用填报单填报明细数据': '复制上月HC使用填报单填报明细数据',
  '新增规划明细': '新增规划明细',
  '向本人所负责的且当前状态处于待规划的产品/平台项目PM推送提醒消息': '向本人所负责的且当前状态处于待规划的产品/平台项目PM推送提醒消息',
  '以点击人员为群主，本人所负责的待规划的产品/平台项目PM为群成员创建飞书群，并发送规划填报提醒消息': '以点击人员为群主，本人所负责的待规划的产品/平台项目PM为群成员创建飞书群，并发送规划填报提醒消息',
  '一月': '一月',
  '二月': '二月',
  '三月': '三月',
  '四月': '四月',
  '五月': '五月',
  '六月': '六月',
  '七月': '七月',
  '八月': '八月',
  '九月': '九月',
  '十月': '十月',
  '十一月': '十一月',
  '十二月': '十二月',
  '项目月': '项目月',
  '应提需求单': '应提需求单',
  '未提需求单': '未提需求单',
  '需规划人月': '需规划人月',
  '已规划人月': '已规划人月',
  '需填报人月': '需填报人月',
  '已填报人月': '已填报人月',
  '统计看板': '统计看板',
  '需求阶段': '需求阶段',
  '规划阶段': '规划阶段',
  '规划审核': '规划审核',
  '填报阶段': '填报阶段',
  '审核阶段': '审核阶段',
  '核定阶段': '核定阶段',
  '归档': '归档',
  '其他': '其他',
  '全部': '全部',
  '客户项目详情': '客户项目详情',
  '编辑客户项目': '编辑客户项目',
  '项目员工编号': '项目员工编码',
  '项目邮箱': '项目邮箱',
  '复制上月需求单中需求明细数据': '复制上月需求单的需求明细数据',
  '新增需求明细': '新增需求明细',
  '确定删除 {username}': '确定删除 {username} ？',
  '查看审批': '查看审批',
  '发起审批': '发起审批',
  '审批摘要': '审批摘要',
  '标题': '标题',
  '单号': '单号',
  '发起人': '发起人',
  '发起时间': '发起时间',
  '抄送': '抄送',
  '待审批': '待审批',
  '拒绝': '拒绝',
  '批量确认': '批量确认',
  '使用填报确认': '使用填报确认',
  '待确认人数': '待确认人数',
  '合计': '合计',
  '审批流程': '审批流程',
  '审批中': '审批中',
  '审批通过': '审批通过',
  '异常': '异常',

  'login.logout': '退出登陆',

  // 通用
  Delete: '删除',
  Edit: '编辑',
  'No Permission': '没有权限',
  'Server Error': '服务器错误',
};
